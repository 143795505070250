import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 643.000000 754.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,754.000000) scale(0.100000,-0.100000)" >

<path d="M2041 6747 c-36 -18 -65 -63 -65 -101 0 -35 42 -109 56 -100 5 3 22
-1 38 -10 17 -8 32 -10 36 -5 3 5 18 16 34 24 26 13 30 13 56 -7 l29 -22 -22
25 c-23 25 -30 49 -15 49 13 0 -3 96 -19 116 -15 18 -69 44 -91 44 -7 0 -24
-6 -37 -13z"/>
<path d="M2363 6563 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2508 6533 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2568 6523 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2708 6493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1970 6460 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M2893 6453 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3098 6413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2380 6396 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M3230 6386 c0 -2 13 -6 29 -8 17 -2 28 -1 25 4 -5 8 -54 12 -54 4z"/>
<path d="M1900 6350 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M2111 6354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2426 6356 c3 -8 13 -17 22 -21 13 -5 13 -2 -3 15 -21 24 -26 25 -19
6z"/>
<path d="M3438 6343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2476 6305 c4 -8 9 -15 13 -15 3 0 24 -16 46 -36 22 -20 77 -67 123
-106 46 -38 82 -71 80 -73 -2 -2 -41 -23 -88 -47 -171 -89 -304 -178 -399
-267 -35 -32 -64 -53 -66 -47 -2 6 -8 61 -14 121 -6 61 -16 160 -22 220 -6 61
-15 133 -19 160 -15 85 -3 -208 16 -379 l16 -155 -60 -68 c-256 -286 -400
-576 -473 -951 -6 -34 -15 -61 -20 -60 -4 2 -63 53 -131 114 -68 61 -153 137
-190 170 -58 51 -66 62 -60 84 12 46 6 90 -17 120 l-22 31 177 297 c97 163
192 322 211 352 18 31 33 62 33 71 0 8 3 19 8 25 4 5 1 3 -6 -6 -20 -24 -145
-219 -219 -342 -37 -62 -72 -120 -78 -129 -5 -10 -37 -64 -69 -120 -91 -160
-81 -148 -118 -143 -44 7 -95 -26 -114 -72 -28 -67 19 -138 99 -151 31 -5 36
-10 55 -67 11 -33 39 -110 60 -171 22 -60 52 -144 66 -185 15 -41 33 -91 41
-110 8 -19 49 -129 90 -245 42 -115 100 -275 130 -355 124 -335 235 -648 235
-662 0 -8 -11 -25 -25 -38 -31 -29 -41 -80 -24 -121 14 -35 62 -69 97 -69 36
0 81 28 102 63 l19 31 88 -3 c144 -5 159 -10 257 -90 111 -91 148 -109 266
-134 78 -16 135 -20 320 -20 207 -1 235 1 351 26 135 28 276 73 378 118 47 22
79 29 124 29 57 0 62 -2 92 -37 29 -34 38 -38 78 -38 37 0 52 6 75 28 34 33
46 75 32 113 -7 17 -6 32 1 47 9 17 9 19 -1 11 -9 -9 -19 -8 -41 3 -32 16 -35
23 -17 41 18 18 38 15 39 -5 1 -10 6 -3 12 15 12 39 97 119 107 101 5 -7 19 5
38 32 16 24 64 84 106 133 94 110 179 234 236 346 24 47 59 107 78 134 21 31
30 52 24 58 -6 6 -1 34 14 76 61 180 95 385 95 584 0 81 -2 152 -5 157 -4 5
-2 20 3 33 6 16 5 27 -4 35 -7 6 -15 35 -19 64 -37 279 -197 637 -383 856 -29
34 -53 65 -53 68 0 22 -264 245 -370 313 -83 53 -260 140 -305 149 -22 5 -107
6 -190 2 -174 -8 -341 -39 -502 -94 l-106 -37 -38 29 c-21 15 -86 67 -145 116
-106 87 -129 100 -118 72z m674 -768 c122 -32 230 -81 230 -106 0 -10 12 -34
28 -54 24 -33 32 -36 75 -37 28 0 61 7 77 17 24 14 30 14 42 2 40 -39 309
-267 658 -557 102 -84 199 -166 217 -181 31 -27 32 -28 18 -67 -10 -29 -20
-41 -42 -46 -15 -4 -73 -19 -128 -33 -55 -14 -125 -32 -155 -40 -150 -39 -219
-59 -260 -76 -39 -16 -42 -18 -17 -19 28 0 228 49 302 75 22 7 78 23 125 35
47 12 104 28 127 35 l42 13 22 -36 c17 -29 19 -42 11 -62 -5 -14 -33 -83 -62
-155 -67 -167 -199 -495 -242 -605 -31 -76 -40 -90 -94 -133 -82 -66 -258
-152 -379 -184 -87 -23 -120 -26 -255 -27 l-155 -1 -158 103 c-87 57 -170 107
-184 113 -14 5 -51 27 -82 48 -31 21 -94 62 -141 92 -47 29 -114 71 -150 94
-134 87 -180 115 -180 110 0 -5 160 -111 366 -240 69 -44 134 -87 143 -96 10
-8 53 -38 97 -67 215 -139 220 -143 189 -136 -89 20 -245 90 -353 158 -40 26
-68 35 -120 40 -37 3 -105 17 -152 31 -166 48 -378 172 -363 212 4 10 9 20 12
23 3 3 11 14 17 25 8 14 25 22 60 25 67 8 92 27 108 80 12 41 12 48 -5 72 -11
14 -19 31 -19 38 0 6 27 47 60 90 33 44 55 77 48 75 -7 -3 -24 -22 -38 -43
-14 -20 -29 -42 -35 -48 -5 -6 -20 -23 -31 -37 -20 -23 -25 -24 -62 -14 l-40
11 -7 105 c-9 150 -15 160 -9 15 l5 -121 -32 -21 -33 -21 -57 50 c-31 27 -61
49 -65 49 -5 0 -1 -7 10 -15 10 -7 37 -32 61 -54 41 -38 42 -41 37 -90 -3 -40
0 -55 18 -81 24 -34 22 -60 -7 -97 l-18 -23 -41 38 c-47 43 -124 136 -160 194
-62 97 -145 300 -130 315 7 7 39 -18 141 -111 41 -38 77 -67 79 -65 2 2 -48
51 -111 108 -63 57 -119 115 -125 130 -17 41 -14 314 5 386 8 33 20 80 27 105
28 110 140 298 238 403 42 43 49 45 55 15 3 -18 5 -16 6 8 1 35 27 62 116 121
116 77 218 120 370 153 125 28 320 22 455 -13z m645 -189 c289 -93 500 -277
636 -553 27 -55 52 -113 55 -129 7 -28 6 -29 -15 -14 -12 9 -43 35 -69 59 -45
43 -450 384 -591 499 -37 30 -93 77 -124 104 -31 26 -64 54 -74 62 -29 23 75
7 182 -28z m-2480 -523 c49 -44 116 -105 149 -136 34 -31 81 -73 105 -95 l44
-39 -7 -110 c-18 -309 53 -645 200 -940 60 -122 63 -127 117 -208 l38 -56 -35
-69 c-34 -67 -35 -68 -70 -64 -32 4 -36 8 -55 61 -11 31 -39 108 -62 171 -22
63 -49 138 -59 165 -10 28 -30 82 -45 120 -14 39 -32 88 -40 110 -24 74 -76
218 -160 450 -46 127 -98 271 -115 320 -18 50 -48 133 -68 185 -78 211 -80
218 -69 229 13 13 21 7 132 -94z m3220 -542 c-10 -130 -36 -235 -90 -373 -31
-76 -196 -313 -209 -299 -4 4 70 196 94 244 5 11 25 61 44 110 51 134 147 378
152 387 13 20 15 2 9 -69z m-2510 -1123 c20 -25 61 -71 91 -102 56 -58 58 -68
14 -63 -14 1 -56 3 -95 4 l-70 2 -22 48 -23 48 31 57 c17 31 32 56 34 54 2 -2
19 -23 40 -48z m1775 -225 c0 -21 -5 -25 -30 -25 -36 0 -38 12 -7 33 31 22 37
21 37 -8z"/>
<path d="M3385 5290 c-10 -11 -16 -22 -13 -25 3 -3 13 6 22 20 20 30 16 32 -9
5z"/>
<path d="M3345 5239 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2212 5200 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3257 5126 c-37 -46 -67 -87 -67 -91 0 -4 32 32 70 80 39 48 70 89
70 91 0 11 -12 -3 -73 -80z"/>
<path d="M2228 5030 c2 -63 6 -117 8 -119 7 -8 2 133 -6 184 -5 32 -6 9 -2
-65z"/>
<path d="M3155 4994 c-9 -15 -12 -23 -6 -20 11 7 35 46 28 46 -3 0 -12 -12
-22 -26z"/>
<path d="M3116 4945 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2243 4850 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M3030 4833 c-35 -42 -111 -143 -106 -143 4 0 104 123 119 147 11 19
4 16 -13 -4z"/>
<path d="M2255 4715 c3 -33 10 -118 16 -190 6 -71 13 -132 16 -135 3 -3 3 23
0 57 -4 34 -10 113 -13 175 -4 62 -11 122 -15 133 -6 13 -7 -2 -4 -40z"/>
<path d="M2874 4633 c-23 -31 -24 -34 -5 -18 22 19 46 55 38 55 -3 0 -17 -17
-33 -37z"/>
<path d="M2806 4546 c-20 -24 -36 -48 -36 -53 0 -8 21 15 61 70 32 43 15 31
-25 -17z"/>
<path d="M2727 4445 c-15 -19 -24 -35 -20 -34 10 0 57 59 51 64 -2 2 -16 -11
-31 -30z"/>
<path d="M2648 4344 c-65 -85 -71 -94 -65 -94 5 0 95 112 111 138 19 32 -8 6
-46 -44z"/>
<path d="M2293 4340 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3800 4324 l-45 -14 40 6 c22 3 44 10 50 15 11 11 12 11 -45 -7z"/>
<path d="M3622 4280 c-18 -4 -36 -11 -39 -14 -8 -7 53 4 82 15 25 10 1 9 -43
-1z"/>
<path d="M3480 4240 c-20 -6 -21 -8 -5 -8 11 0 31 4 45 8 20 6 21 8 5 8 -11 0
-31 -4 -45 -8z"/>
<path d="M2552 4220 c-7 -11 -9 -20 -4 -20 4 0 13 9 20 20 7 11 9 20 4 20 -4
0 -13 -9 -20 -20z"/>
<path d="M3335 4200 c-25 -11 1 -11 35 0 20 6 21 8 5 8 -11 0 -29 -3 -40 -8z"/>
<path d="M3227 4173 c-32 -9 -56 -17 -54 -20 6 -5 116 23 125 31 8 8 -4 6 -71
-11z"/>
<path d="M3070 4133 c-14 -3 -29 -8 -34 -13 -7 -7 62 4 89 15 13 5 -23 3 -55
-2z"/>
<path d="M2880 4079 c-63 -17 -117 -32 -119 -35 -8 -8 19 -4 76 12 32 9 85 23
118 31 33 8 62 16 64 19 10 9 -30 2 -139 -27z"/>
<path d="M2680 4025 c-25 -7 -36 -13 -25 -13 11 0 38 5 60 13 52 17 24 17 -35
0z"/>
<path d="M2548 3991 c-36 -9 -69 -19 -73 -22 -9 -10 124 19 143 31 22 12 10
11 -70 -9z"/>
<path d="M4058 6300 c-14 -10 -31 -33 -38 -49 -24 -60 28 -141 91 -141 16 0
32 -7 38 -17 9 -16 10 -16 11 5 0 12 6 22 14 22 21 0 56 62 56 100 0 49 -55
100 -108 100 -24 0 -49 -8 -64 -20z"/>
<path d="M3638 6303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1850 6264 c-12 -19 -21 -37 -18 -39 2 -2 14 14 26 36 28 49 22 52
-8 3z"/>
<path d="M3828 6263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3978 6233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1790 6165 c-7 -9 -10 -18 -7 -21 3 -3 10 4 17 15 13 25 8 27 -10 6z"/>
<path d="M1741 6077 c-8 -12 -21 -32 -29 -44 -8 -13 -12 -23 -9 -23 7 0 59 81
55 85 -2 2 -10 -6 -17 -18z"/>
<path d="M1657 5939 c-16 -27 -26 -49 -24 -49 7 0 59 91 55 95 -2 2 -16 -18
-31 -46z"/>
<path d="M4884 5208 c-63 -20 -86 -120 -40 -175 19 -23 34 -30 67 -32 24 -1
47 -7 51 -14 6 -9 8 -8 8 2 0 8 11 27 25 41 82 86 2 213 -111 178z"/>
<path d="M5043 4685 c-23 -22 -65 -105 -54 -105 5 0 16 -19 26 -43 17 -42 18
-42 72 -45 63 -4 93 11 117 59 20 40 20 48 1 86 -20 40 -51 60 -101 64 -31 3
-45 -1 -61 -16z"/>
<path d="M4883 4573 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4723 4053 c-10 -16 -22 -31 -28 -35 -6 -5 -6 -8 2 -8 6 0 20 16 32
35 24 41 19 47 -6 8z"/>
<path d="M4099 3143 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M4056 3084 c-15 -20 -25 -38 -22 -41 3 -4 7 -4 9 -2 1 2 13 19 25 37
32 45 23 50 -12 6z"/>
<path d="M6408 223 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6378 203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}